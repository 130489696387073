import React from 'react'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>The page you're looking for doesn't exist...</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
